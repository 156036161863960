@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

body{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    background:#f8f8f8;
}

.container{
    max-width: 1440px;
    width: 100%;
    padding:0 35px;
    margin: 0 auto;
}

.img>img{
    width: 100%;
    height: 100%;
    object-position: center;
}

.img.contain>img{
    object-fit: contain;
}

.img.cover>img{
    object-fit: cover;
}

h1{
    font-size: 2.5em;
    font-weight: 600;
}

h2{
    font-size: 2.2em;
    font-weight: 600;
}

h3{
    font-size: 1.8em;
    font-weight: 600;
}

h4{
    font-size: 1.4em;
    font-weight: 600;
}

h5{
    font-size: 1em;
    font-weight: 600;
}

h6{
    font-size: 0.8em;
    font-weight: 600;
}

@media only screen and (max-width: 1440px){
 body{
     font-size: 16px;
 }
}

@media only screen and (max-width: 1024px){
    body{
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px){
    .container{
        padding: 0 20px;
    }
}

.cap-container{
    box-shadow: 0px 2px 2px 0px #00000040;
    border-radius: 4px;
}

.download-btn{
    cursor: pointer;
    height: 18px;
    margin-left: 10px;
    color: #808080;
}

.clr-wh{
    color: #fff !important;
}

.va-middle{
    vertical-align: middle;
}

.cp{
    cursor: pointer;
}