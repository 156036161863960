.m-r-0{
    margin-right: 0px;
}

.m-l-0{
    margin-left: 0px;
}

.m-t-0{
    margin-top: 0px;
}

.m-b-0{
    margin-bottom: 0px;
}

.m-t-5{
    margin-top: 5px;
}

.m-b-5{
    margin-bottom: 5px;
}

.m-l-5{
    margin-left: 5px;
}

.m-r-5{
    margin-right: 5px;
}

.m-t-10{
    margin-top: 10px;
}

.m-b-10{
    margin-bottom: 10px;
}

.m-l-10{
    margin-left: 10px;
}

.m-r-10{
    margin-right: 10px;
}

.m-t-15{
    margin-top: 15px;
}

.m-b-15{
    margin-bottom: 15px;
}

.m-l-15{
    margin-left: 15px;
}

.m-r-15{
    margin-right: 15px;
}

.m-t-20{
    margin-top: 20px;
}

.m-t-25{
    margin-top: 25px;
}

.m-b-20{
    margin-bottom: 20px;
}

.m-l-20{
    margin-left: 20px;
}

.m-r-20{
    margin-right: 20px;
}

.m-t-30{
    margin-top: 30px;
}

.m-b-30{
    margin-bottom: 30px;
}

.m-l-30{
    margin-left: 30px;
}

.m-r-30{
    margin-right: 30px;
}

.bold{
    font-weight: bold;
}

.w100{
    width: 100% !important;
}

.p-l-5{
    padding-left: 5px;
}

.p-l-10{
    padding-left: 10px;
}

.p-l-15{
    padding-left: 15px;
}

.p-l-20{
    padding-left: 20px;
}

.p-t-20{
    padding-top: 20px;
}

.p-t-30{
    padding-top: 30px;
}


.fw-3{
    font-weight: 300 !important;
}

.fw-4{
    font-weight: 400 !important;
}

.fw-5{
    font-weight: 500 !important;
}

.m-l--15{
    margin-left: -15px !important;
}

.clr-white{
    color: #fff !important;
}

.clr-black{
    color: #000 !important;
}

.m-t-70{
    margin-top: 70px;
}

.text-center{
    text-align: center;
}

.m-b-40{
    margin-bottom: 40px;
}

.p-b-30{
    padding-bottom: 30px;
}

.va-middle{
    vertical-align: middle;
}

.display-block{
    display: block;
}

.m-t-40{
    margin-top: 40px;
}

.m-t-50{
    margin-top: 50px;
}

.m-b-50{
    margin-bottom: 50px;
}
